import {
    Alert,
    Button,
    Collapse,
    Dropdown,
    Popover,
    Rate,
    Skeleton,
    Space,
    Table,
    Tag,
    message,
    notification,
} from 'antd';
import { Card, Select, Text, TextArea } from 'components/basic';
import Field from 'components/basic/Field/Field';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import CustomerSummary from 'components/pages/Customers/CustomerDetailed/CustomerSummary';
import DriverSummary from 'components/pages/Drivers/DriverDetailed/DriverSummary';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { useParams } from 'react-router-dom';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getCustomer, getDriver, getTrip, getTripCancellationReasons, updateTrip } from 'services/message.service';
import moment from 'moment';
import useModal from 'hooks/useModal';
import Modal from 'antd/lib/modal/Modal';
import { useAuth0 } from '@auth0/auth0-react';
import SendEReceiptModal from './SendEReceiptModal';
import { DownOutlined } from '@ant-design/icons';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { useNavigate } from 'react-router-dom';

const TripDetailedPage = () => {
    let { id } = useParams();
    const [driverDoesNotExist, setDriverDoesNotExist] = React.useState(false);
    const [fareBreakdown, setFareBreakdown] = React.useState<any[]>([]);
    const [cancellationReason, setCancellationReason] = React.useState<any[]>([]);
    const [cancellationReasonDescription, setCancellationReasonDescription] = React.useState<any>('');
    const [forceCompleteReason, setForceCompleteReason] = React.useState<any>('');
    const [disableButton, setDisableButton] = React.useState<any>(false);
    const [cancellationReasons, setCancellationReasons] = React.useState<any[]>([]);
    const [claims, setClaims] = React.useState<any>({});
    const [customerDoesNotExist, setCustomerDoesNotExist] = React.useState(false);
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const eReceiptModal = useModal();
    const userRole = useContext(AuthUserContext);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };

    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.VIEW) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const hasEditAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    useMount(() => {
        fetchTrip(id + '');
        const detectUser = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                getIdTokenClaims().then(claims => {
                    setClaims(claims);
                });
            }
        };
        detectUser();
    });

    const user_role = useMemo(() => {
        return claims.user_role;
    }, [claims]);

    const { request, result, loading } = useApi({
        api: getTrip,
    });

    const {
        request: customerRequest,
        result: customerResult,
        loading: customerLoading,
    } = useApi({
        api: getCustomer,
    });

    const {
        request: driverRequest,
        result: driverResult,
        loading: driverLoading,
    } = useApi({
        api: getDriver,
    });

    const { request: cancellationReasonRequest, loading: cancellationReasonLoading } = useApi({
        api: getTripCancellationReasons,
    });

    const {
        request: cancelTripRequest,
        loading: cancelTripLoading,
        result: cancelTripResult,
    } = useApi({
        api: updateTrip,
    });

    const fetchTrip = useCallback(
        async (id: string) => {
            try {
                request({ id }).then(res => {
                    const customerId = res?.data?.customer?.id;
                    customerRequest({ id: customerId }).catch(() => {
                        setCustomerDoesNotExist(true);
                    });
                    const driverId = res?.data?.driver?.driver_id || res?.data?.driver?.id;
                    if (driverId) {
                        driverRequest({ id: driverId }).catch(() => {
                            setDriverDoesNotExist(true);
                        });
                    } else {
                        setDriverDoesNotExist(true);
                    }
                });
            } catch (error) {
                throw error;
            }
        },
        [request, customerRequest, driverRequest],
    );

    const fetchCancellationReasons = useCallback(
        async (id: string) => {
            try {
                cancellationReasonRequest({ id }).then(res => {
                    if (res?.data && res?.data.length > 0) {
                        setCancellationReasons(res.data);
                        setCancellationReason(res.data[0].id);
                    }
                });
            } catch (error) {
                throw error;
            }
        },
        [cancellationReasonRequest],
    );

    const handleCancelTrip = async (status = '') => {
        const res = await cancelTripRequest({
            id: id + '',
            body: {
                status: status,
                cancelation_reason_id: status === 'operator_canceled' ? cancellationReason : '',
                description: status === 'operator_canceled' ? cancellationReasonDescription : forceCompleteReason,
            },
        });

        const { error, message } = res;
        if (!error) {
            notification.success({
                message: 'Success',
                description: 'Trip successfully updated.',
                duration: 3,
            });
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }

        fetchTrip(id + '');
        verifyCancelModal.close();
    };

    const data = result?.data || {};
    const driver = driverResult?.data || {};
    const customer = customerResult?.data || {};

    const cancelModal = useModal();
    const verifyCancelModal = useModal();
    const forceCompleteModal = useModal();

    const handleOnShowModal = async () => {
        setCancellationReasonDescription('');
        fetchCancellationReasons(id + '');
        cancelModal.show();
    };

    const onShowForceTripModal = async () => {
        setForceCompleteReason('');
        forceCompleteModal.show();
    };

    const cancellationReasonOptions = useMemo(() => {
        return cancellationReasons.map(reason => {
            return {
                label: reason.message,
                value: reason.id,
            };
        });
    }, [cancellationReasons]);

    useEffect(() => {
        let breakdown = [];
        if (result?.data?.price) {
            if (result?.data?.price.total) {
                breakdown.push({
                    key: 'subtotal',
                    item: 'Total Fare',
                    amount: `${result?.data?.price?.total?.toFixed(2)}`,
                    description: '',
                    moreInfo: '',
                });
            }
            if (result?.data?.price?.add_ons_items) {
                breakdown.push({
                    key: 'add_on',
                    item: 'Add-on: Raincoat',
                    amount: `${result?.data?.price?.add_ons_items[0].price?.toFixed(2)}`,
                    description: '',
                    moreInfo: '',
                });
            }

            if (result?.data?.price?.coupon_code) {
                breakdown.push({
                    key: 'discount',
                    item: 'Discount',
                    amount: `- ${result?.data?.price?.discount?.toFixed(2)}`,
                    description: `Coupon Code: ${result?.data?.price?.coupon_code || '-'}`,
                    moreInfo: '',
                });
            } else if (result?.data?.price.discount == 0) {
                breakdown.push({
                    key: 'discount',
                    item: 'Discount',
                    amount: `- ${result?.data?.price?.discount?.toFixed(2)}`,
                    description: `Coupon Code: ${result?.data?.price?.coupon_code || '-'}`,
                    moreInfo: '',
                });
            }

            if (result?.data?.price.surcharge) {
                breakdown.push({
                    key: 'surcharge',
                    item: 'Surcharge',
                    amount: `${result?.data?.price.currency} ${result?.data?.price?.surcharge?.toFixed(2)}`,
                    description: '',
                    moreInfo: '',
                });
            } else if (result?.data?.price.surcharge == 0) {
                breakdown.push({
                    key: 'surcharge',
                    item: 'Surcharge',
                    amount: `${result?.data?.price.currency} ${result?.data?.price?.surcharge?.toFixed(2)}`,
                    description: '',
                    moreInfo: '',
                });
            }
        }
        setFareBreakdown(breakdown);
    }, [result, setFareBreakdown]);

    const items = [
        {
            key: '1',
            label: (
                <div>
                    <Text type="label">Send E-receipt to:</Text>
                    <Text className="italic text-gray">{customer.email || 'Customer has no registered email.'}</Text>
                </div>
            ),
            disabled: !customer.email,
            onClick: () =>
                eReceiptModal.show({
                    id: id,
                    email: customer.email,
                    disableButton: (c: boolean) => setDisableButton(c),
                }),
        },
        {
            key: '2',
            label: 'Send to another email',
            onClick: () =>
                eReceiptModal.show({ id: id, email: '', disableButton: (c: boolean) => setDisableButton(c) }),
        },
    ];

    return (
        <DetailContentLayout
            goBackOnClick={handleBackClick}
            actionComponent={
                data?.status === 'complete' &&
                (claims?.user_role === 'SUPER_ADMIN' ||
                    claims?.user_role === 'OPERATOR' ||
                    claims?.user_role === 'VISMIN_ONBOARDING' ||
                    claims?.user_role === 'ONBOARDING_MANAGER' ||
                    claims?.user_role === 'SERVICE_EXCELLENT_AGENT' ||
                    claims?.user_role === 'SERVICE_EXCELLENT_SUPPORT') && (
                    <Dropdown menu={{ items }} disabled={disableButton}>
                        <Button type="primary" loading={customerLoading}>
                            <Space>
                                Send E-receipt
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                )
            }
        >
            <div className="flex gap-2 mt-4">
                <div className="w-80 flex flex-col gap-2">
                    <Card className="rounded p-lg border border-dashed border-slate-200" title="Trip Summary">
                        <div className="flex flex-col gap-2">
                            <Skeleton active loading={loading} />
                            <Skeleton active loading={loading} />
                            {!loading && (
                                <>
                                    {data?.service_id === 'passenger_bike' && (
                                        <Field label="Service Type" value="Passenger" valueClassName="capitalize" />
                                    )}
                                    {data?.service_id === 'padala_bike' && (
                                        <Field label="Service Type" value="Padala" valueClassName="capitalize" />
                                    )}
                                    {data?.service_id === 'passenger_four_seater' && (
                                        <Field
                                            label="Service Type"
                                            value="Passenger Four Seater"
                                            valueClassName="capitalize"
                                        />
                                    )}
                                    {data?.service_id === 'passenger_six_seater' && (
                                        <Field
                                            label="Service Type"
                                            value="Passenger Six Seater"
                                            valueClassName="capitalize"
                                        />
                                    )}

                                    <Field label="Service Zone" value={data?.service_zone} />

                                    <div>
                                        <Text type="label">Status</Text>
                                        {data?.status == 'complete' && (
                                            <Tag color="green" className="uppercase">
                                                {data?.status?.replaceAll('_', ' ')}
                                            </Tag>
                                        )}
                                        {data?.status == 'terminated' && (
                                            <Tag color="red" className="uppercase">
                                                {data?.status?.replaceAll('_', ' ')}
                                            </Tag>
                                        )}
                                        {(data?.status == 'finding_driver' ||
                                            data?.status == 'driver_otw_pickup' ||
                                            data?.status == 'driver_arrived_pickup' ||
                                            data?.status == 'driver_otw_destination' ||
                                            data?.status == 'driver_arrived_destination') && (
                                            <Tag color="geekblue" className="uppercase">
                                                {data?.status?.replaceAll('_', ' ')}
                                            </Tag>
                                        )}
                                        {data?.status?.indexOf('driver_not_found') > -1 && (
                                            <Tag color="volcano" className="uppercase">
                                                {data?.status?.replaceAll('_', ' ')}
                                            </Tag>
                                        )}
                                        {data?.status?.indexOf('canceled') > -1 && (
                                            <Tag color="red" className="uppercase">
                                                {data?.status?.replaceAll('_', ' ')}
                                            </Tag>
                                        )}
                                    </div>

                                    <div>
                                        <Text type="label">Date Requested</Text>
                                        <Text color="text-black-secondary">
                                            {data?.created_at ? (
                                                <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                    {moment
                                                        .utc(data.created_at)
                                                        .add(8, 'hour')
                                                        .format('MMMM DD, YYYY hh:mm:ss A')}
                                                </span>
                                            ) : (
                                                <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                    -
                                                </span>
                                            )}
                                        </Text>
                                    </div>
                                    {data?.status === 'complete' && (
                                        <div>
                                            <Text type="label">Driver Picked-up at</Text>
                                            <Text color="text-black-secondary">
                                                {data?.metadata?.pickup_timestamp ? (
                                                    <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                        {moment
                                                            .utc(data?.metadata?.pickup_timestamp)
                                                            .add(8, 'hour')
                                                            .format('MMMM DD, YYYY hh:mm:ss A')}
                                                    </span>
                                                ) : (
                                                    <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                        Not Picked up
                                                    </span>
                                                )}
                                            </Text>
                                        </div>
                                    )}
                                    <div>
                                        <Text type="label">Date Last Updated</Text>
                                        <Text color="text-black-secondary">
                                            {data?.updated_at ? (
                                                <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                    {moment
                                                        .utc(data.updated_at)
                                                        .add(8, 'hour')
                                                        .format('MMMM DD, YYYY hh:mm:ss A')}
                                                </span>
                                            ) : (
                                                <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                    -
                                                </span>
                                            )}
                                        </Text>
                                    </div>

                                    {data?.status == 'complete' && (
                                        <div>
                                            <Text type="label">Date Completed</Text>
                                            <Text color="text-black-secondary">
                                                {data?.updated_at ? (
                                                    <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                        {moment
                                                            .utc(data.updated_at)
                                                            .add(8, 'hour')
                                                            .format('MMMM DD, YYYY hh:mm:ss A')}
                                                    </span>
                                                ) : (
                                                    <span className="underline decoration-dotted cursor-pointer font-semibold text-sm">
                                                        -
                                                    </span>
                                                )}
                                            </Text>
                                        </div>
                                    )}

                                    {data?.payment?.payment_method_type && (
                                        <Field label="Payment Method" value={data?.payment?.payment_method_type} />
                                    )}

                                    <Field label="Trip Request ID" value={data?.trip_request_id} valueType="uuid" />
                                    <div className="flex gap-2 justify-between items-center">
                                        {hasEditAccess(PagesEnum.TRIPS) && (
                                            <Button
                                                danger
                                                type="ghost"
                                                disabled={
                                                    !(
                                                        data?.status === 'finding_driver' ||
                                                        data?.status === 'driver_otw_pickup' ||
                                                        data?.status === 'driver_arrived_pickup' ||
                                                        data?.status === 'driver_otw_destination' ||
                                                        data?.status === 'driver_arrived_destination' ||
                                                        data?.status === 'driver_found'
                                                    )
                                                }
                                                onClick={handleOnShowModal}
                                                className={user_role === 'SUPER_ADMIN' ? 'w-1/2' : 'w-full'}
                                            >
                                                Cancel Trip
                                            </Button>
                                        )}
                                        {hasEditAccess(PagesEnum.TRIPS) && (
                                            <Button
                                                danger
                                                type="primary"
                                                className="w-1/2"
                                                disabled={
                                                    data?.status === 'finding_driver' ||
                                                    data?.status === 'complete' ||
                                                    data?.status === 'driver_canceled' ||
                                                    data?.status === 'customer_canceled' ||
                                                    data?.status === 'operator_canceled' ||
                                                    data?.status === 'driver_not_found' ||
                                                    data?.status === 'terminated'
                                                }
                                                onClick={onShowForceTripModal}
                                            >
                                                Force Complete Trip
                                            </Button>
                                        )}
                                    </div>
                                    {data?.cancelation?.description ? (
                                        <div>
                                            <Text type="label">Force Cancel Reason</Text>
                                            <Text color="text-black-secondary">
                                                {data?.cancelation?.description ? (
                                                    <span
                                                        style={{ overflowWrap: 'anywhere' }}
                                                        className="overflowWrap decoration-dotted cursor-pointer font-semibold text-sm"
                                                    >
                                                        {data?.cancelation?.description}
                                                    </span>
                                                ) : (
                                                    <span className="decoration-dotted cursor-pointer font-semibold text-sm">
                                                        -
                                                    </span>
                                                )}
                                            </Text>
                                        </div>
                                    ) : null}
                                    {data?.metadata?.force_complete_reason ? (
                                        <div>
                                            <Text type="label">Force Complete Reason</Text>
                                            <Text color="text-black-secondary">
                                                {data?.metadata?.force_complete_reason ? (
                                                    <span
                                                        style={{ overflowWrap: 'anywhere' }}
                                                        className="overflowWrap decoration-dotted cursor-pointer font-semibold text-sm"
                                                    >
                                                        {data?.metadata?.force_complete_reason}
                                                    </span>
                                                ) : (
                                                    <span className="decoration-dotted cursor-pointer font-semibold text-sm">
                                                        -
                                                    </span>
                                                )}
                                            </Text>
                                        </div>
                                    ) : null}
                                    {user_role === 'SUPER_ADMIN' && (
                                        <Alert
                                            showIcon
                                            type="warning"
                                            message="You can cancel any trip as long as it is still in transit while Force Completing a trip ensure the orderly closure of trips when required."
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Card>

                    <Card title="Customer Review" className="rounded p-lg border border-dashed border-slate-200">
                        <Skeleton round active loading={loading} paragraph />
                        <Skeleton round active loading={loading} paragraph />

                        {!loading && !data?.feedback && (
                            <p className="p-4 rounded bg-slate-50 text-gray-400 text-center m-0">No Reviews</p>
                        )}

                        {!loading && data?.feedback && (
                            <>
                                <Rate
                                    disabled
                                    defaultValue={data?.feedback?.rating || 0}
                                    style={{ fontSize: '1.5rem' }}
                                />

                                <p className="text-gray-400 m-0">{data?.feedback?.rating} out of 5 stars</p>

                                <p className="m-0 uppercase text-gray-500 font-semibold">What could be improved?</p>
                                <div className="mb-4">
                                    {data?.feedback?.feedback?.map((feedback: any) => {
                                        return <Tag className="font-semibold mt-0">{feedback}</Tag>;
                                    })}
                                </div>

                                {data?.feedback?.comments && (
                                    <>
                                        <p className="m-0 uppercase text-gray-500 font-semibold">Comments</p>
                                        <div className="bg-slate-50 px-12 py-4 rounded relative mt-4 mb-4">
                                            <img
                                                src="/images/quote-open.png"
                                                className="w-8 absolute opacity-20"
                                                style={{ top: '-10px', left: '-10px' }}
                                            />
                                            <Text className="text-gray-500">{data?.feedback?.comments || '-'}</Text>
                                            <img
                                                src="/images/quote-close.png"
                                                className="w-8 absolute opacity-20"
                                                style={{
                                                    bottom: '-10px',
                                                    right: '-10px',
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </Card>
                </div>
                <div className="flex-1 flex flex-col gap-2">
                    <Card title="Trip Details" className="rounded p-lg border border-dashed border-slate-200">
                        <Skeleton round active loading={loading} paragraph />
                        <Skeleton round active loading={loading} paragraph />

                        {!loading && (
                            <>
                                <p className="m-0 uppercase text-gray-500">Pick-up Details</p>
                                <div className="grid grid-cols-2 gap-2">
                                    <Field label="Main Address" value={data?.pickup?.address?.main} />
                                    <Field label="Secondary Address" value={data?.pickup?.address?.secondary} />
                                </div>
                                <p className="m-0 uppercase text-gray-500">Drop-off Details</p>
                                <div className="grid grid-cols-2 gap-2">
                                    <Field label="Main Address" value={data?.dropoff?.address?.main} />
                                    <Field label="Secondary Address" value={data?.dropoff?.address?.secondary} />
                                </div>

                                <p className="m-0 uppercase text-gray-500">Customer Notes</p>

                                <div className="bg-slate-50 px-12 py-4 rounded relative mt-4 mb-4">
                                    <img
                                        src="/images/quote-open.png"
                                        className="w-8 absolute opacity-20"
                                        style={{ top: '-10px', left: '-10px' }}
                                    />
                                    <p style={{ overflowWrap: 'anywhere' }} className="text-gray-500 overflowWrap">
                                        {data?.notes || '-'}
                                    </p>{' '}
                                    <img
                                        src="/images/quote-close.png"
                                        className="w-8 absolute opacity-20"
                                        style={{ bottom: '-10px', right: '-10px' }}
                                    />
                                </div>
                            </>
                        )}
                    </Card>

                    {/* <Card
                        title="Timeline"
                        className="rounded p-lg border border-dashed border-slate-200"
                    >
                        <p className="text-red-500">this section is still in progress</p>
                        <Skeleton round active loading={loading} paragraph />
                        <Skeleton round active loading={loading} paragraph />
                        {!loading && (
                            <div className="mt-4">
                                <Timeline
                                    pending="Driver and passenger on the way to Dropoff..."
                                    mode="alternate"
                                >
                                    <Timeline.Item>
                                        Trip requested at 2023-04-01 10:34 AM
                                    </Timeline.Item>
                                    <Timeline.Item color="green">
                                        Trip accepted by driver at 2023-04-01 10:35 AM
                                    </Timeline.Item>
                                    <Timeline.Item>
                                        Driver arrived at pickup location at 2023-04-01
                                        10:36 AM
                                    </Timeline.Item>
                                </Timeline>
                            </div>
                        )}
                    </Card> */}

                    <Card title="Fare Breakdown" className="rounded p-lg border border-dashed border-slate-200">
                        <Table
                            pagination={false}
                            columns={[
                                {
                                    title: 'Item',
                                    dataIndex: 'item',
                                    key: 'item',
                                },
                                {
                                    title: 'Description',
                                    dataIndex: 'description',
                                    key: 'description',
                                    render: (text, record) => {
                                        return (
                                            <div className="flex flex-col gap-1">
                                                {record.moreInfo ? (
                                                    <div className="flex items-center gap-2">
                                                        <Text className="text-gray-600">{text}</Text>
                                                        <Popover
                                                            content={record.moreInfo}
                                                            title="Additional Information"
                                                        >
                                                            <QuestionCircleOutlined className="cursor-pointer" />
                                                        </Popover>
                                                    </div>
                                                ) : (
                                                    <Text className="text-gray-600">{text}</Text>
                                                )}
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: 'Amount',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    align: 'right',
                                },
                            ]}
                            dataSource={fareBreakdown}
                        ></Table>
                        <div className="flex justify-between items-center px-2 mt-2">
                            <Text className="text-gray-700 font-semibold">Final Trip Fare</Text>
                            <Text className="text-sm font-semibold">
                                {data?.price?.currency} {data?.price?.final?.toFixed(2)}
                            </Text>
                        </div>
                        <div className="flex justify-between items-center px-2">
                            <Text className="text-gray-700 font-semibold">
                                Service Fee ({data?.price?.commission_percentage}%)
                            </Text>
                            <Text className="text-sm font-semibold text-red-500">
                                - {data?.price?.currency} {data?.price?.service_fee?.toFixed(2)}
                            </Text>
                        </div>
                        {result?.data?.price?.tip && (
                            <div className="flex justify-between items-center px-2">
                                <div className="flex items-center gap-1">
                                    <Text className="text-gray-700 font-semibold">Biker Gift</Text>
                                </div>
                                <Text className="text-sm font-semibold">
                                    {data?.price?.currency} {result?.data?.price?.tip.toFixed(2)}
                                </Text>
                            </div>
                        )}

                        <div className="flex justify-between items-center px-2">
                            <Text className="text-sm text-gray-700 font-semibold">Driver Earnings</Text>
                            <Text className="text-md font-semibold">
                                {data?.price?.currency} {data?.price?.driver_earnings?.toFixed(2)}
                            </Text>
                        </div>
                    </Card>

                    <div className="rounded p-lg border border-dashed border-slate-200">
                        <Skeleton round active loading={loading} paragraph />
                        <Skeleton round active loading={loading} paragraph />

                        {!loading && (
                            <Collapse className="card-collapse" bordered={false} expandIconPosition="right" ghost>
                                <CollapsePanel header={'Advanced Information for Developers'} key="1" className="p-0">
                                    <div className="mb-4">
                                        <Alert
                                            message="These details are for troubleshooting purposes only."
                                            type="info"
                                            showIcon
                                        />
                                    </div>

                                    <p className="m-0 uppercase text-gray-500 font-semibold">Pick-up Parameters</p>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Field
                                            label="Plus Code (Compound Code)"
                                            value={data?.pickup?.plus_code?.compound_code}
                                        />
                                        <Field
                                            label="Plus Code (Global Code)"
                                            value={data?.pickup?.plus_code?.global_code}
                                            copyable
                                        />

                                        <Field label="Latitude" value={data?.pickup?.latitude} copyable />
                                        <Field label="Longitude" value={data?.pickup?.longitude} copyable />
                                        <Field label="Angkas Place ID" value={data?.pickup?.angkas_place_id} copyable />
                                    </div>

                                    <p className="m-0 uppercase text-gray-500 font-semibold mt-4">
                                        Drop-off Parameters
                                    </p>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Field
                                            label="Plus Code (Compound Code)"
                                            value={data?.dropoff?.plus_code?.compound_code}
                                        />
                                        <Field
                                            label="Plus Code (Global Code)"
                                            value={data?.dropoff?.plus_code?.global_code}
                                            copyable
                                        />

                                        <Field label="Latitude" value={data?.dropoff?.latitude} copyable />
                                        <Field label="Longitude" value={data?.dropoff?.longitude} copyable />
                                        <Field
                                            label="Angkas Place ID"
                                            value={data?.dropoff?.angkas_place_id}
                                            copyable
                                        />
                                    </div>

                                    <p className="m-0 uppercase text-gray-500 font-semibold mt-4">
                                        Additional Information
                                    </p>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div>
                                            <Text type="label">Payment ID</Text>
                                            <Text type="uuid">{data?.payment?.id || '-'}</Text>
                                        </div>
                                        <div>
                                            <Text type="label">Payment Method ID</Text>
                                            <Text type="uuid">{data?.payment?.payment_method_id || '-'}</Text>
                                        </div>
                                    </div>
                                </CollapsePanel>
                            </Collapse>
                        )}
                    </div>
                </div>
                <div className="w-80 flex flex-col gap-2">
                    <Card title="Driver Details" className="rounded p-lg border border-dashed border-slate-200">
                        <Skeleton round active loading={loading || driverLoading} paragraph={{ rows: 8 }} />

                        {!loading && !driverLoading && driverDoesNotExist && (
                            <p className="p-4 rounded bg-slate-50 text-gray-400 text-center m-0">
                                {data?.status === 'terminated'
                                    ? 'This trip is terminated, driver details are not available.'
                                    : 'No Driver'}
                            </p>
                        )}

                        {!loading && !driverLoading && !driverDoesNotExist && hasActionAccess(PagesEnum.DRIVERS) && (
                            <DriverSummary
                                data={{
                                    first_name: driver.first_name,
                                    last_name: driver.last_name,
                                    id: data?.driver?.driver_id || data?.driver?.id,
                                    public_id: driver.public_id,
                                    gender: driver.gender,
                                    phone_number: driver.phone_number,
                                    license_number: driver.license_number,
                                    license_expiry_date: driver.license_expiry_date,
                                    avatar_url: driver.avatar_url,
                                    account_status: driver.account_status,
                                    service_types: driver.service_types,
                                    fleets: driver.fleets,
                                }}
                                page="trip"
                                expanded={true}
                                refreshData={() => fetchTrip(id + '')}
                            />
                        )}
                    </Card>

                    {hasActionAccess(PagesEnum.CUSTOMERS) && (
                        <Card title="Customer Details" className="rounded p-lg border border-dashed border-slate-200">
                            <Skeleton round active loading={loading || customerLoading} paragraph={{ rows: 8 }} />

                            {!loading && !customerLoading && (
                                <CustomerSummary
                                    data={{
                                        first_name: customer.first_name,
                                        last_name: customer.last_name,
                                        id: data?.customer?.id,
                                        public_id: customer.public_id,
                                        gender: customer.gender,
                                        phone_number: customer.phone_number,
                                        avatar_url: customer?.avatar_url,
                                        email: customer?.email,
                                    }}
                                    expanded={true}
                                    page="trip"
                                />
                            )}
                        </Card>
                    )}

                    <Card title="Support" className="rounded p-lg border border-dashed border-slate-200">
                        <p className="font-semibold text-sm text-gray-800 m-0 relative">
                            Experiencing technical problems regarding this trip?
                        </p>

                        <div className="flex mt-4 mb-4">
                            <a href="https://app.clickup.com/14274986/v/fm/dkmda-12587" target="_blank">
                                <Button danger>File a bug through Click-Up</Button>
                            </a>
                        </div>
                        <p className="font-normal text-xs text-gray-600 m-0 relative">
                            Be sure to add the following details when filing a bug report:
                        </p>
                        <ul className="list-disc list-inside text-left decoration-none relative p-0 mt-2 mb-0">
                            <li>Trip Request ID</li>
                            <li>Summary of the Problem</li>
                            <li>Date and time when the problem happened</li>
                        </ul>
                    </Card>
                </div>
            </div>
            <Modal
                {...cancelModal}
                okButtonProps={{
                    danger: true,
                    disabled: !cancellationReason || cancellationReasonLoading,
                    loading: cancellationReasonLoading,
                    onClick: () => {
                        let reason = cancellationReasons.find(reason => reason.id === cancellationReason);

                        if (reason && reason.mandatory_description && !cancellationReasonDescription) {
                            message.error('Please enter a description.');
                            return;
                        }

                        cancelModal.close();
                        verifyCancelModal.show();
                    },
                }}
                okText="Yes, Cancel Trip"
                cancelText="No, Don't Cancel"
            >
                <div className="flex flex-col gap-2">
                    <Text type="heading">Cancel Trip</Text>
                    <p className="m-0">Select Cancellation Reason:</p>
                    <Select
                        onChange={setCancellationReason}
                        className="m-0"
                        value={cancellationReason}
                        options={cancellationReasonOptions}
                        loading={cancellationReasonLoading}
                        disabled={cancellationReasonLoading}
                    ></Select>
                    {cancellationReasons.find(reason => reason.id === cancellationReason)?.mandatory_description && (
                        <TextArea
                            onChange={e => {
                                setCancellationReasonDescription(e.target.value);
                            }}
                            className="mb-4"
                            placeholder="Enter Description..."
                            style={{ height: 120 }}
                            defaultValue={cancellationReasonDescription}
                            disabled={cancellationReasonLoading}
                            value={cancellationReasonDescription}
                            maxLength={150}
                            showCount
                        />
                    )}
                </div>
            </Modal>
            <Modal
                {...verifyCancelModal}
                okText="Yes, I am Sure"
                okButtonProps={{
                    danger: true,
                    loading: cancelTripLoading,
                    disabled: cancelTripLoading,
                    onClick: () => {
                        handleCancelTrip('operator_canceled');
                    },
                }}
                cancelText="No, Don't Cancel"
                cancelButtonProps={{
                    disabled: cancelTripLoading,
                    onClick: () => {
                        verifyCancelModal.close();
                        setCancellationReasonDescription('');
                    },
                }}
            >
                <div className="flex flex-col gap-4">
                    <Text type="heading">Cancel Trip</Text>
                    <Text type="label">Are you sure you want to cancel this trip? This action cannot be undone.</Text>
                </div>
            </Modal>
            <Modal
                {...forceCompleteModal}
                okText="Confirm Force Complete"
                okButtonProps={{
                    loading: cancelTripLoading,
                    disabled: cancelTripLoading || forceCompleteReason === '' || !forceCompleteReason,
                    onClick: () => {
                        handleCancelTrip('complete');
                        forceCompleteModal.close();
                    },
                }}
                cancelButtonProps={{
                    disabled: cancelTripLoading,
                    onClick: () => {
                        forceCompleteModal.close();
                        setForceCompleteReason('');
                    },
                }}
            >
                <div className="flex flex-col gap-4">
                    <Text type="heading">Force Complete Trip</Text>
                    <Text>Are you sure you want to forcibly complete this trip on behalf of customer or driver?</Text>
                    <div>
                        <div className="border-solid border border-gray-300 py-sm px-md rounded-t-lg flex justify-between items-center">
                            <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                                Trip ID
                            </Text>
                            <div className="text-right my-xs">
                                <Text
                                    fontWeight="font-semibold"
                                    size="text-md"
                                    className="leading-none py-xs"
                                    color="text-blue"
                                >
                                    {data?.trip_request_id}
                                </Text>
                            </div>
                        </div>
                        <div className="border-solid border border-gray-300 py-xs px-md border-t-0 flex justify-between items-center">
                            <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                                Driver Name
                            </Text>
                            <div className="text-right my-xs">
                                <Text fontWeight="font-semibold" size="text-md" className="leading-none">
                                    {data?.driver?.driver_name}
                                </Text>
                                <Text className="leading-none mt-xs">Driver ID: {driver?.public_id}</Text>
                            </div>
                        </div>
                        <div className="border-solid border-t-0 border border-gray-300 py-xs px-md rounded-b-lg flex justify-between items-center">
                            <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                                Customer Name
                            </Text>
                            <div className="text-right my-xs">
                                <Text fontWeight="font-semibold" size="text-md" className="leading-none">
                                    {data?.customer?.name}
                                </Text>
                                <Text className="leading-none mt-xs">Customer ID: {customer?.public_id}</Text>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="m-0">
                            Complete Trip Reason: <span className="text-red-500">*</span>
                        </p>
                        <TextArea
                            maxLength={150}
                            showCount
                            onChange={e => {
                                setForceCompleteReason(e.target.value);
                            }}
                            className="m-0"
                            value={forceCompleteReason}
                            disabled={cancellationReasonLoading}
                        />
                        <p className="m-0 text-gray-400">
                            Reason for the forced trip completion will be recorded and displayed in the trip history for
                            reference.
                        </p>
                    </div>
                </div>
            </Modal>
            <SendEReceiptModal eReceiptModal={eReceiptModal} />
        </DetailContentLayout>
    );
};

export default TripDetailedPage;
