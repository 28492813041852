import { Button, Table } from 'components';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { getCustomersTripHistory } from 'services/message.service';
import { columns } from './columns';
import CustomerTripHistoryFilter from './CustomerTripHistoryFilter/CustomerTripHistoryFilter';
import { useNavigate } from 'react-router-dom';

const CustomerTripHistory = ({ id }: { id: string | undefined }) => {
    const [data, setData] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const navigate = useNavigate();

    const pageSize = 10;
    const [pagination, setPagination]: any = useState({
        current: 1,
        defaultCurrent: 1,
        defaultPageSize: pageSize,
    });

    const [showMore, setShowMore] = useState(false);

    const { modifyFilters, requestState } = useFilter({
        page_size: pageSize,
        page: 1,
        id,
    });

    const { request, result, loading } = useApi({
        api: getCustomersTripHistory,
    });

    useMount(() => {
        fetchTripHistory(
            {
                id,
                ...requestState,
            },
            false,
        );
    });

    const fetchTripHistory = useCallback(
        async (requestState: {}, onNext: boolean) => {
            try {
                const result = await request(requestState);
                const d = result.data;
                setShowMore(d?.history?.length >= pageSize);
                let trips = d?.history?.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                if (onNext) {
                    let newTrips: any = data.concat(trips);
                    setData(newTrips);
                } else {
                    setData(trips);
                }
            } catch (error) {
                throw error;
            }
        },
        [request, data, setShowMore, pageSize],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchTripHistory(requestState || {}, false);
        },
        [fetchTripHistory, modifyFilters],
    );

    const viewMore = useCallback(() => {
        const page = pagination.current + 1;
        fetchTripHistory(
            {
                ...requestState,
                page,
            },
            true,
        );
        setPagination({
            current: page,
            defaultCurrent: page,
        });
    }, [pagination, fetchTripHistory, setPagination, requestState]);

    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            status: filter.status,
            trip_date: filter.trip_date,
            start_date: filter.date_range[0],
            end_date: filter.date_range[1],
        });
        await fetchTripHistory(
            {
                ...requestState,
                page: 1,
                status: filter.status,
                trip_date: filter.trip_date,
                start_date: filter.date_range[0],
                end_date: filter.date_range[1],
            },
            false,
        );
    };

    const onRefresh = useCallback(async () => {
        setIsRefreshing(true);
        await fetchTripHistory(
            {
                id,
                ...requestState,
            },
            false,
        );
        setIsRefreshing(false);
    }, [requestState, fetchTripHistory, id]);

    const columnsWithClassName = columns.map((column) =>
        {
            if (column.key === 'trip_id') {
                return { ...column, className: 'trip-id-cell' };
            } else if (column.key === 'action') {
                return { ...column, className: 'action-cell' };
            }
            return column;
        });
    

    return (
        <div>
            <ListLayout
                title="Trips History"
                actionComponent={
                    <div>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', marginRight: '16px' }}>
                            Please refresh to obtain the latest status of ongoing trips{' '}
                        </span>
                        <Button onClick={onRefresh} type="primary">
                            Refresh
                        </Button>
                    </div>
                }
            >
                <Table
                    className="custom-table-hover"
                    loading={(loading && pagination.current === 1) || isRefreshing}
                    columns={columnsWithClassName}
                    onRow={(record) => ({
                        onClick: (event) => {
                            const target = event.target as HTMLElement;
                            const isRestrictedCell = target.closest('.trip-id-cell') || target.closest('.action-cell');
                            if (!isRestrictedCell) {
                                navigate(`/trips/${record.trip_id}`);
                            }
                        },
                        style: { cursor: 'pointer' },
                    })}
                    dataSource={data}
                    pagination={false}
                    onChange={onTableChange}
                />
            </ListLayout>

            {showMore && (
                <div className="p-4 items-center flex w-full content-center">
                    <div className="flex-1"></div>
                    <Button onClick={viewMore} loading={loading} disabled={loading}>
                        View More
                    </Button>
                    <div className="flex-1"></div>
                </div>
            )}
            <style>{`
                .custom-table-hover .ant-table-tbody > tr:hover > td {
                    background-color: #e6f7ff !important;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default CustomerTripHistory;
