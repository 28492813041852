import { Alert, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomNotification } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { editLayer } from 'services/message.service';

interface EditLayerModalProps {
    modal: any;
    onSuccess?: () => void;
    name?: string;
    description?: string;
}

const EditLayerModal: React.FC<EditLayerModalProps> = ({ modal, onSuccess, name, description }) => {
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: '',
        description: '',
    });

    let { id } = useParams();

    useEffect(() => {
        if (modal.show) {
            setInitialValues({
                name: name || '',
                description: description || '',
            });
            form.setFieldsValue({
                name: name || '',
                description: description || '',
            });
        }
    }, [modal.show, name, description, form]);

    const handleOnClose = () => {
        modal.close();
        form.resetFields();
    };

    const { request, loading } = useApi({
        api: editLayer,
    });

    const handleFormChange = (changedValues: any) => {
        setIsFormDirty(true);
    };

    const editLayers = async (id: any, values: any) => {
        const res = await request({
            id,
            values,
        });
        
        if (!res?.error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: <p><b>{res?.data?.name}</b> Layer Updated</p>,
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: res?.error?.message,
            });
        }
    };

    const handleFormFinish = async () => {
        form.validateFields()
            .then(values => {
                editLayers(id, values);
            })
            .catch(errorInfo => {});
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Edit Layer"
            onOk={handleFormFinish}
            okText="Save"
            okButtonProps={{
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <Form form={form} initialValues={initialValues} onValuesChange={handleFormChange}>
                <Form.Item name="name" label="Layer Name" rules={[{ required: true, message: 'Field is required' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Field is required' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditLayerModal;
